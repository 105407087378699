<template>
  <v-container v-if="layout">
    <v-card flat>
      <v-toolbar flat class="headline">
        <v-btn
          icon
          text
          small
          class="mx-4"
          :to="{ name: 'layouts' }"
        >
          <v-icon>
            mdi-chevron-left
          </v-icon>
        </v-btn>
        {{ layout.name }}
        <v-spacer />
        <v-btn
          icon
          text
          small
          class="mx-4"
          @click="$store.state.previewDrawer = !$store.state.previewDrawer"
        >
          <v-icon>
            mdi-eye
          </v-icon>
        </v-btn>
      </v-toolbar>
      <v-tabs show-arrows>
        <v-tab :to="`/layouts/${layout.id}`" exact>
          General
        </v-tab>
        <v-tab :to="`/layouts/${layout.id}/theme`">
          Theme
        </v-tab>
        <v-tab :to="`/layouts/${layout.id}/widgets`">
          Widgets
        </v-tab>
        <v-tab :to="`/layouts/${layout.id}/plugins`">
          Plugins
        </v-tab>
        <v-tab :to="`/layouts/${layout.id}/pages`">
          Pages
        </v-tab>
      </v-tabs>
      <v-card-text>
        <v-fade-transition mode="out-in">
          <router-view />
        </v-fade-transition>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['layout'])
  },
  watch: {
    '$route.params.id': {
      handler(layoutId) {
        this.layoutId !== layoutId && this.$store.dispatch('setLayoutId', layoutId)
      },
      immediate: true,
      deep: true
    }
  },
  beforeRouteLeave(from, to, next) {
    this.$store.dispatch('setLayoutId', undefined)
    next()
  }
}
</script>
